<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters v-if="point">
      <v-col cols="12">
        <v-row no-gutters>

          <!-- Topbar -->
          <v-col cols="12">
            <v-row class=" justify-space-between" no-gutters>
              <div>
                <IconModeSimple :mode="mode" />
              </div>
              <div class="font-weight-bold">
                <v-row no-gutters align="center">
                  <v-col cols="12" class="mb-1">
                    <v-img v-if="brandLogo" :src="brandLogo" width="40" style="margin: 0 auto !important;"/>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">{{point.name}}</div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <div>
                      <!--v-icon>far fa-location</v-icon-->
                      <span v-if="point.distance < 1000">{{Math.round(point.distance).toFixed(0)}}m</span>
                      <span v-else>{{roundNumberV1(point.distance / 1000, 2)}}km</span>
                    </div>
                  </template>

                  <span>({{aroundDistance}})</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6" v-if="isLoaded">

            <!-- WC -->
            <div v-if="point.physical_mode === 'WC'">
              <p v-if="point.data && JSON.parse(point.data).hours">Horaires : <span class="font-weight-bold">{{JSON.parse(point.data).hours}}</span></p>
              <p v-if="point.data && JSON.parse(point.data).state">Statut : {{JSON.parse(point.data).state}}</p>
            </div>

            <!-- E_SCOOTER -->
            <div v-if="point.physical_mode === 'ELECTRIC_SCOOTER'">
              <p v-if="point.slug && point.slug !== 'null'">Marque : {{point.slug}}</p>
              <p v-if="point.battery && point.battery !== 'null'">Batterie : {{point.battery}}%</p>
            </div>

            <!-- BIKE_STATION -->
            <div v-if="point.physical_mode === 'BIKE_STATION'">
              <p v-if="point.slug">Station {{point.slug}}</p>
              <p v-if="point.availableVehicles && point.availableVehicles !== 'null'">Vélos : {{point.availableVehicles}}</p>
              <p v-if="point.availableStands && point.availableStands !== 'null'">Places : {{point.availableStands}}</p>
            </div>

            <!-- CAR -->
            <div v-if="point.physical_mode === 'CAR'">
              <p v-if="point.battery && point.battery !== 'null'">Batterie : {{point.battery}}%</p>
            </div>

            <!-- MOTORCYCLE -->
            <div v-if="point.physical_mode === 'MOTORCYCLE'">
              <p v-if="point.battery && point.battery !== 'null'">Batterie : {{point.battery}}%</p>
            </div>

            <!-- dynamic -->
            <div v-else-if="['METRO', 'TRAIN', 'TRAMWAY', 'SHUTTLE', 'BUS', 'BOAT'].includes(point.physical_mode)">
              <div v-if="nextStops.length > 0">
                <template v-for="(departure) of nextStops">
                  <v-card class="my-2">
                    <v-row no-gutters align="center" justify="space-between" class="py-2">
                      <v-col cols="2" class="text-center">
                        <v-avatar :color="`#${departure.route.line.color}`" class="white--text font-weight-bold" size="40" >{{departure.display_informations.label}}</v-avatar>
                      </v-col>
                      <v-col cols="7">{{departure.display_informations.headsign}}</v-col>
                      <v-col cols="3" class="text-right">
                        <span class="mx-2">{{formatTime(departure.stop_date_time.arrival_date_time)}}</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </div>
              <div v-else-if="nextStops.length === 0">Aucune information disponible !</div>
            </div>

            <!-- RER -->
            <div v-else-if="['RER'].includes(point.physical_mode)">
              <div v-if="nextStops.length > 0">
                <template v-for="(departure) of nextStops">
                  <v-card class="my-2">
                    <v-row no-gutters align="center" justify="space-between" class="py-2">
                      <v-col cols="2" class="text-center">
                        <v-avatar :color="`#${departure.route.line.color}`" class="white--text font-weight-bold" size="40" >{{departure.display_informations.label}}</v-avatar>
                      </v-col>
                      <v-col cols="7">{{departure.display_informations.direction}}</v-col>
                      <v-col cols="3" class="text-right">
                        <span class="mx-2">{{formatTime(departure.stop_date_time.arrival_date_time)}}</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </div>
              <div v-else-if="nextStops.length === 0">Aucune information disponible !</div>
            </div>

            <!-- AED -->
            <div v-else-if="point.physical_mode === 'DEFIBRILLATOR'">
              <p>Adresse: <span class="font-weight-bold">{{pointData.address}} {{pointData.zipCode}} {{pointData.city}}</span></p>
              <p v-if="pointData.alwaysOpened !== 'null' && pointData.alwaysOpened === 1" class="font-weight-bold">Ouvert 24h/24</p>
            </div>

          </v-col>

          <!-- Loader -->
          <v-col cols="12" class="mt-6" v-else>
            <Skeleton
              type="list-item-avatar-three-line"
              :occurrence="1" />
          </v-col>

          <v-col cols="12" class="mt-6">
            <v-row no-gutters justify="space-between" v-if="['ELECTRIC_SCOOTER', 'BIKE_STATION', 'CAR', 'MOTORCYCLE'].includes(point.physical_mode)">
              <v-btn color="primary" @click="goTo">Go !</v-btn>
              <v-btn @click="redirect(point.website)">Réserver</v-btn>
            </v-row>

            <v-row no-gutters justify="center" v-else>
              <v-btn color="primary" class="font-weight-bold" @click="goTo">GO</v-btn>
            </v-row>
          </v-col>

        </v-row>


      </v-col>

    </v-row>
    <v-row no-gutters v-else>
      <Skeleton />
    </v-row>
  </v-container>
</template>

<script>
import modes from "@/constants/modes.json";
import brands from "@/constants/brands.json";

export default {

  name: "PointDetails",

  components: {
    Skeleton: () => import("@/components/Common/Skeleton"),
    IconModeSimple: () => import("@/components/Common/IconModeSimple")
  },

  data() {
    return {
      isLoaded: true,
      nextStops: [],
      watchPoint: () => null,
      modes,
      brands
    }
  },

  created() {

    if (this.point === null)
      this.$router.replace({name: "Home"});

    this.$store.commit("setShowLeftPanel", true);
  },

  methods: {

    getNextStops() {
      this.isLoaded = false;
      this.$http
        .get(`/map/viewer/next-stops?coords=${this.coords.lat},${this.coords.lng}&stationID=${this.$route.params.id}`, {
          headers: this.$session.exists() ? {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          } : {}
        })
        .then((res) => {
          console.log(res.data);
          this.nextStops = res.data.departures;
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
          this.$router.replace({name: "Home"});
        })
      .finally(() => {
        this.isLoaded = true;
      })
    },

    roundNumberV1(num, scale) {

      if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
      } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
          sig = "+";
        }
        var i = +arr[0] + "e" + sig + (+arr[1] + scale);
        var j = Math.round(i);
        var k = +(j + "e-" + scale);
        return k;
      }
    },

    goTo() {
      this.$router.push({name: 'Itinerary', query: {to: `${this.point.lat},${this.point.lng}`}});
    },

    redirect(link) {
      window.open(link);
    },

    formatTime(datetime) {
      if (datetime) {
        const diff = this.$moment(datetime).diff(this.$moment(), "minutes");
        if (diff <= 0)
          return "A quai";
        else if (diff > 100)
          return this.$moment(datetime).format("HH:mm");

        return `${diff}min`;
      }

      return "";
    }
  },

  computed: {

    coords() {
      return this.$store.getters["coords"];
    },

    point() {
      if (this.$store.getters['modes/getCurrentPoint']) {
        return this.$store.getters['modes/getCurrentPoint'].properties;
      }

      return this.$store.getters["modes/getCurrentPoint"];
    },

    iconEnabledURL() {
      return require(`@/assets/icons/modes/${this.mode.icon_enabled}`);
    },


    mode() {
      return modes.find(el => el.name === this.point?.physical_mode);
    },

    aroundDistance() {
      if (this.point.distance < 1000) {
        return this.$t('view.point-details.around-distance-meters', {distance: this.point.distance});
      }
      return this.$t('view.point-details.around-distance-km', {distance: Math.round(this.point.distance/1000)});
    },

    brandLogo() {

      const physical_mode = this.brands.filter(item => (item.type === this.point.physical_mode && item.name === this.point.name));
      if(physical_mode.length !== 0) {
        return require(`@/assets/pictures/brands/${physical_mode[0].logo}`);
      } else {
        return null;
      }
    },

    pointData() {
      if (this.point && this.point.data)
        return JSON.parse(this.point.data);
    }
  },

  watch: {
    point(newValue, oldValue) {
      console.log("Point changed");
    }
  },

  beforeDestroy() {
    this.watchPoint();
  }
}
</script>

<style scoped>

</style>